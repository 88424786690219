* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

a:hover {
  color: #c49041 !important;
  transform: all ease 0.3s;
}

body {
  background-color: #17232c;
}

.heroContent {
  width: 100%;
  margin: 0 auto;
  display: flex;

  align-items: center;
  flex-direction: column;

  height: 100vh;
}

.frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;

  background-color: #17232c;
}

.frame header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  width: 100%;
  margin: 40px 0;
}

.frame .logo {
  height: 74px;
  position: relative;
  width: auto;
}

.frame .navbar {
  align-items: center;
  display: inline-flex;

  gap: 40px;
  position: relative;
}

.frame .text-wrapper {
  color: #ffffff;
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .button-instance {
  flex: 0 0 auto !important;
}

.frame .overlap-group {
  height: 846px;
  position: relative;
  width: 1280px;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #6a6c6d;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c49041;
  border-radius: 10px;
}

@import url("https://fonts.googleapis.com/css2?family=Gloock&family=Oswald:wght@300;400;500;600;700&display=swap");

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffc46b;
}

.hero-content {
  background-image: url("./assets/images/heroPic.png");
  background-position: center center;
  background-size: cover;
  height: 745px;
  width: 100%;
  max-width: 1280px;
  border-radius: 24px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.scrollIcon {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.hero-content .p {
  color: #ffffff;
  font-family: "Gloock", Helvetica;
  font-size: 48px;
  font-weight: 400;
  left: 130px;
  letter-spacing: 0.96px;
  line-height: normal;
  text-align: center;
  max-width: 1020px;
  margin-bottom: 32px;
}

.hero-content .text-wrapper-2 {
  color: #ffffff;
  font-family: "Oswald", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 273px;
  letter-spacing: 0.48px;
  line-height: normal;
  text-align: center;
  max-width: 732px;
  margin-bottom: 45px;
}

.frame .design-component-instance-node {
  left: 561px !important;
  position: absolute !important;
  top: 497px !important;
}

.frame .div-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 160px;
  margin: 80px auto 160px auto;
  width: 100%;
}

.frame .discover {
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 88px;
}

.frame .div-4 {
  align-items: flex-start;
  display: inline-flex;

  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.frame .text-wrapper-3 {
  color: var(--variable-collection-gold);
  font-family: "Oswald", Helvetica;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame .logo-2 {
  height: 160px;
  position: relative;
  width: auto;
}

.frame .lorem-ipsum-dolor {
  color: #ffffff;
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.36px;
  line-height: 30.6px;
  text-align: justify;
  max-width: 952px;
}

.frame .stats {
  background-color: #0b151c;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
  padding: 40px 16px;
  text-align: center;
  width: 100%;
  max-width: 1280px;
}

.frame .CHF-billion-in {
  color: transparent;
  font-family: "Gloock", Helvetica;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.frame .span {
  color: #c49041;
}

.frame .text-wrapper-4 {
  color: #ffffff;
}

.frame .div-5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 846px;
  width: 100%;
}

.frame .div-6 {
  align-items: center;
  display: inline-flex;

  flex-direction: column;
  justify-content: center;
  position: relative;
}

.frame .div-7 {
  color: transparent;
  font-family: "Gloock", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-5 {
  color: #ffffff;
  font-family: "Oswald", Helvetica;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper-6 {
  color: var(--variable-collection-gold);
  font-family: "Gloock", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .expertise {
  align-items: flex-start;
  display: flex;

  gap: 156px;
  width: 100%;
  max-width: 1280px;
}

.frame .div-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: 240px;
}

.frame .text-wrapper-7 {
  color: #ffffff;
  font-family: "Gloock", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 56px;
  width: 100%;
  max-width: 884px;
}

.frame .text-wrapper-8 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.36px;
  line-height: 30.6px;
  text-align: justify;
}

.frame .div-10 {
  align-items: flex-start;
  display: flex;

  flex-direction: column;
  gap: 48px;
  width: 100%;
  max-width: 884px;
}

.frame .div-11 {
  align-items: flex-start;
  display: flex;

  gap: 56px;
  width: 100%;
  max-width: 884px;
}

.frame .text-wrapper-9 {
  color: var(--variable-collection-gold);
  font-family: "Oswald", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper-10 {
  color: #ffffff;
  font-family: "Gloock", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 36px;
  position: relative;
  width: 300px;
}

.frame .div-12 {
  align-items: flex-start;
  display: inline-flex;

  flex-direction: column;
  gap: 12px;
}

.frame .text-wrapper-11 {
  color: var(--variable-collection-gold);
  font-family: "Oswald", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 30.6px;
  text-align: justify;
  max-width: 597px;
  width: 100%;
}

.frame .text-wrapper-12 {
  color: #ffffff;
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.36px;
  line-height: 30.6px;
  position: relative;
  text-align: justify;
  max-width: 528px;
  width: 100%;
}

.frame .div-13 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 528px;
}

.frame .text-wrapper-13 {
  color: var(--variable-collection-gold);
  font-family: "Oswald", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 30.6px;
  margin-right: -69px;
  width: 100%;
  text-align: justify;
  max-width: 597px;
}

.frame .div-14 {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 56px;
}

.frame .div-15 {
  align-items: center;
  display: inline-flex;

  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.frame .text-wrapper-14 {
  color: #ffffff;
  font-family: "Gloock", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 376px;
}

.frame .div-16 {
  align-items: flex-start;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
  position: relative;
}

.frame .div-17 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
  width: 380px;
}

.frame .div-wrapper {
  align-items: center;
  background-color: var(--variable-collection-gold);
  border-radius: 24px;
  display: flex;

  flex-direction: column;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 24px;
  position: relative;
  width: 61px;
}

.frame .text-wrapper-15 {
  color: var(--variable-collection-dark-blue);
  font-family: "Gloock", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.frame .text-wrapper-16 {
  color: #ffffff;
  font-family: "Gloock", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper-17 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Oswald", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.32px;
  line-height: 28.8px;
  position: relative;
  text-align: center;
}

.frame .div-wrapper-2 {
  align-items: center;
  background-color: var(--variable-collection-gold);
  border-radius: 24px;
  display: inline-flex;

  flex-direction: column;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 24px;
  position: relative;
}

.frame .text-wrapper-18 {
  color: #ffffff;
  font-family: "Gloock", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 523px;
}

.frame .div-18 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 80px;
  position: relative;
}

.contactImg {
  width: 100%;
  height: auto;
}

.frame .image-wrapper {
  background-color: #ffffff;
  border-radius: 24px;
  height: 350px;
  overflow: hidden;
  position: relative;
  width: 609px;
}

.frame .image {
  height: 350px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 609px;
}

.frame .div-19 {
  align-items: flex-start;
  display: inline-flex;

  flex-direction: column;
  gap: 56px;
  position: relative;
}

.frame .div-20 {
  align-items: center;
  display: flex;

  justify-content: space-between;
  gap: 24px;
  max-width: 591px;
}

.frame .div-21 {
  align-items: flex-start;
  display: inline-flex;

  flex-direction: column;
  gap: 8px;
  position: relative;
}

.frame .icon-instance-node {
  height: 40px !important;
  position: relative !important;
  width: 40px !important;
}

.frame .div-wrapper-3 {
  align-items: flex-start;
  display: inline-flex;

  flex-direction: column;
  justify-content: center;
  position: relative;
}

.frame .text-wrapper-19 {
  color: #c49041;
  font-family: "Oswald", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper-20 {
  color: var(--variable-collection-gold);
  font-family: "Oswald", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper-21 {
  align-self: stretch;
  color: var(--variable-collection-gold);
  font-family: "Oswald", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.frame .stats-2 {
  align-items: center;
  background-color: #0b151c;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 56px;
  justify-content: center;
  padding: 80px;
  position: relative;
  max-width: 1280px;
  width: 100%;
}

.frame .div-22 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;

  justify-content: space-between;
  position: relative;
  width: 100%;
}

.frame .div-23 {
  align-items: flex-start;
  display: inline-flex;

  gap: 160px;
  position: relative;
}

.frame .div-24 {
  align-items: flex-start;
  display: inline-flex;

  flex-direction: column;
  gap: 24px;
  position: relative;
}

.frame .text-wrapper-22 {
  color: var(--variable-collection-gold);
  font-family: "Oswald", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.48px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper-23 {
  color: #ffffff;
  font-family: "Oswald", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper-24 {
  color: var(--variable-collection-gold);
  font-family: "Oswald", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.mobileMenu,
.navbarMobile {
  display: none;
}

footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

footer a {
  color: #ffffff;
}

.div-wrapper-3 > a:hover {
  color: white !important;
}

footer .logo {
  width: 160px !important;
  height: auto !important;
}

@media screen and (max-width: 1365px) {
  body {
    margin: 0 24px;
  }

  .frame .discover {
    flex-direction: column;
    gap: 40px;
    width: 100%;
  }

  .frame .lorem-ipsum-dolor,
  .frame .div-9,
  .frame .text-wrapper-11,
  .frame .text-wrapper-12,
  .frame .div-11,
  .frame .div-10,
  .frame .div-13,
  .frame .text-wrapper-13,
  .frame .text-wrapper-12 {
    max-width: unset;
  }

  .frame .expertise {
    flex-direction: column;
    gap: 40px;
  }

  .frame .div-12 {
    display: flex;
    flex: unset;
  }

  .frame .icon-instance-node {
    height: 32px !important;
    width: 32px !important;
  }
}

@media screen and (max-width: 1280px) {
  .heroContent {
    justify-content: unset;
  }

  .navbar {
    display: none !important;
  }

  .mobileMenu {
    display: flex;
    width: 40px;
  }

  .frame .logo {
    height: 73px;
    width: auto;
  }

  .display {
    display: flex;
  }

  .none {
    display: none !important;
  }

  .navbarMobile {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(8px);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    background-color: rgb(24 36 45 / 88%);
  }

  .x {
    position: absolute !important;
    top: 24px;
    right: 24px;
    font-size: 24px !important;
  }

  .frame .text-wrapper-19 {
    font-size: 24px;
  }
}

@media screen and (max-width: 992px) {
  body {
    margin: 0 24px;
  }
  .frame .div-5 {
    flex-direction: column;
    gap: 56px;
  }
  .contactImg {
    display: none;
  }
  .frame .div-18 {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    width: 100%;
    max-width: unset;
  }
  .frame .div-20,
  .frame .div-21 {
    width: 100%;
  }

  .frame .text-wrapper-18 {
    max-width: 100%;
    width: 100%;
  }

  .frame .div-22 {
    align-items: center;
    width: 100%;
    flex-direction: column;
    gap: 80px;
  }

  .frame .div-19 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 56px;
    position: relative;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .hero-content .p {
    font-size: 32px;
    max-width: 820px;
    margin-bottom: 24px;
  }

  .hero-content .text-wrapper-2 {
    font-size: 18px;
    font-weight: 400;
    max-width: 632px;
    margin-bottom: 32px;
  }

  .frame .CHF-billion-in {
    font-size: 32px;
  }
  .frame .text-wrapper-7,
  .frame .text-wrapper-14,
  .frame .text-wrapper-18 {
    font-size: 32px;
  }
}

@media screen and (max-width: 766px) {
  .frame .div-11 {
    flex-direction: column;
    gap: 8px;
  }

  .frame .text-wrapper-10 {
    width: unset;
  }

  .frame .text-wrapper-14,
  .frame .div-17 {
    width: unset;
  }

  .frame .div-23 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
  }

  .frame .stats-2 {
    padding: 80px 40px;
  }
}

@media screen and (max-width: 576px) {
  .frame .div-20 {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .frame .div-23 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .frame .div-24 {
    width: 100%;
    max-width: 300px;
    text-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 420px) {
  .hero-content .p {
    font-size: 18px;
    margin-bottom: 16px;
    padding: 0 8px;
  }
  .hero-content .text-wrapper-2 {
    font-size: 15px;
  }
  .frame header {
    margin: 16px 0;
  }
  .heroContent {
    height: 97vh;
  }
}

@media screen and (max-width: 320px) {
  .frame .logo {
    height: 72px;
    width: auto;
  }
}
