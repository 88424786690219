.button {
  all: unset;
  align-items: center;
  background-color: var(--variable-collection-gold);
  border-radius: 24px;
  box-sizing: border-box;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 12px 32px 15px;
  color: var(--variable-collection-dark-blue);
  font-family: "Oswald", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.18px;
  line-height: normal;
  width: fit-content;
}

.button:hover {
  color: var(--variable-collection-dark-blue) !important;
  cursor: pointer;
  background: #eea83c;
  box-shadow: 0px 0px 24px 0px rgba(196, 144, 65, 0.4);
  transition: background 300ms linear;
}
